import { ROLE_STAFF_OPS, ROLE_STAFF_SALES, ROLE_STAFF_MANAGER, ROLE_CLIENT, ROLE_PARTNER, ROLE_ADMIN, ROLE_STAFF_PANIC } from '~/const/user/roles'

export default {
    computed: {
        isAdmin () {
            return this.$user.role === ROLE_ADMIN
        },
        isOps () {
            return this.$user.role === ROLE_STAFF_OPS
        },
        isSales () {
            return this.$user.role === ROLE_STAFF_SALES
        },
        isManager () {
            return this.$user.role === ROLE_STAFF_MANAGER
        },
        isPanicMode () {
            return this.$user.role === ROLE_STAFF_PANIC
        },
        isClient () {
            return this.$user.role === ROLE_CLIENT || this.$user.role === ROLE_PARTNER
        },
        isPartner () {
            return this.$user.role === ROLE_PARTNER || this.$user.role === ROLE_CLIENT
        }
    }
}
